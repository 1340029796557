import React from 'react';

function HomeAboutFeatures() {
    return (
        <div id="features" className="wrapper spacer p-top-xl">
            <div className="row gutter-width-md with-pb-lg">
                <div className="col-sm-12 col-md-4 col-lg-4 col-xl-4">
                    <div className="feature-item">
                        <h2>Unsere Werte</h2>
                    </div>
                </div>

                <div className="col-sm-12 col-md-4 col-lg-4 col-xl-4">
                    <div className="feature-item">
                        <h4>Moderne Behandlungsmöglichkeiten</h4>
                       {/*  <p>It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged.</p> */}
                    </div>
                </div>

                <div className="col-sm-12 col-md-4 col-lg-4 col-xl-4">
                    <div className="feature-item">
                        <h4>Breiter Leistungsumfang<br /><br /></h4>
                       {/*  <p>It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged.</p> */}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default HomeAboutFeatures;
