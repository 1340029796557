import React from 'react';
import { Link } from 'react-scroll';

const PageTitleHome = () => {
    return (
        <section id="page-title" className="block with-img with-information-items" style={ { backgroundImage: `url('assets/img/placeholder/IMG_1925.webp')` } }>
            <div className="wrapper d-flex">
                <div className="align-self-center w-100">
                    <div className="title">
                    <h1 className="medium">Behandlungsschwerpunkte:<br />
                    Zahnersatz,<br />
                    Funktionsstörungen des Kauorgans,
                    <br />
                    Implantologie</h1>
                    </div>
                    <div className="description spacer p-top-lg text-max-width">
                        <p>Wir sind für Sie da.</p>
                    </div>

                    <div className="spacer p-top-lg no-space">
                        <Link to="about-us" href="#about-us" className="btn btn-primary scroll-to-id">Mehr erfahren</Link>
                    </div>
                </div>
            </div>

            <div className="page-title-bg-color"></div>
        </section>
    );
};

export default PageTitleHome;
