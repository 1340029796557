import React from 'react';
import { Link } from 'react-scroll';

const HomeAboutText = () => {
    return (
        <div id="about-text" className="wrapper">
            <div className="row gutter-width-md with-pb-md">
                <div className="col-sm-12 col-md-5 col-lg-5 col-xl-5">
                    <div className="description-2">
                        <h2>Wir versorgen Sie mit der bestmöglichen Behandlung</h2>
                    </div>

                    <div className="signature spacer m-top-lg">
                        <div className="signature-img">
                            <img src="assets/img/demo/dr.png" alt="Signature" />
                        </div>

                        <div className="signature-content">
                            <h5 className="signature-name">Dr. Rahimi</h5>
                            <p className="signature-position">Zahnarzt</p>
                        </div>
                    </div>
                </div>

                <div className="col-sm-12 col-md-7 col-lg-7 col-xl-7">
                    <div className="description-2">
                        <h5>Vielfältiges Leistungsangebot</h5>
                        <p>Unter anderem bieten wir Ihnen Laserbehandlungen und Implantologie.</p>
                    </div>

                    <div className="description-2 spacer p-top-lg">
                        <h5>Langjährige Erfahrung</h5>
                        <p>Herr Dr. Rahimi greift auf jahrzehnte lange Erfahrung und stetige Weiterbildung zurück.</p>
                    </div>

                    <div className="button spacer p-top-lg">
                        <Link to="services" href="#services" className="btn btn-primary scroll-to-id">Leistungen ansehen</Link>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default HomeAboutText;
