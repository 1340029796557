import React from 'react';

const Copyright = () => {
    return (
        <div className="copyright">
            <p>© { new Date().getFullYear() } {/* Dentist & Medical React JS Template by <a href="https://www.adveits.com">Adveits</a> */}Zahnarzt Dr. Rahimi</p>
        </div>
    );
};

export default Copyright;
