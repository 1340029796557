import React from 'react';

import ContactForm from '../../components/form/ContactForm';

const ContactsUs = () => {
    return (
        <section id="contacts" className="block bg-gray-light spacer m-top-xl p-top-xl p-bottom-xl">
            <div className="wrapper">
                <div className="title text-max-width-2">
                    <h2>Haben Sie Fragen? Kontaktieren Sie uns gerne und wir werden uns bei Ihnen melden.</h2>
                </div>

               {/*  <div className="description text-max-width-2">
                    <p>Bei wahrhaftigen Vorfällen und in der Berechtigung zum rechtskräftigen Urteil, wer den schlichten Realitäten nicht gewachsen ist, die in der Gegenwart existieren.</p>
                </div> */}

                <div className="contact-form-shortcode spacer p-top-lg">
                    <ContactForm />
                </div>
            </div>
        </section>
    );
};

export default ContactsUs;