import React from 'react';
import { Link } from 'react-scroll';

const HomeInfo = () => {
    return (
        <section id="information" className="block">
            <div className="wrapper">
                <div className="row no-gutters information-items">
                    <div className="col-sm-12 col-md-4 col-lg-4 col-xl-4">
                        <div className="information information-color-1">
                            <div className="information-icon">
                                <i className="hosco-icon-call-2"></i>
                            </div>

                            <h4 className="information-t-head">Rufen Sie Uns An</h4>

                            <div className="information-description">
                                <p>Sie wollen einen Termin vereinbaren? Rufen Sie uns gerne gleich an.</p>
                            </div>

                            <div className="information-btn">
                                <a title="Call us now" className="btn btn-sm btn-link btn-line p-0 border-0 min-w-auto link-no-space text-uppercase" href="tel:+4989616666">089/616666</a>
                            </div>
                        </div>
                    </div>

                    <div className="col-sm-12 col-md-4 col-lg-4 col-xl-4">
                        <div className="information information-color-2 information-icon-margin">
                            <div className="information-icon">
                                <i className="hosco-icon-dental-care"></i>
                            </div>

                            <h4 className="information-t-head">So finden Sie Uns</h4>

                            <div className="information-description">
                                <p>Sie finden unsere Praxis direkt über dem Norma in der Münchner-Strasse, der Eingang befindet sich auf der Rückseite des Gebäudes.</p>
                            </div>

                            <div className="information-btn">
                                <Link to="google-maps" title="Auf Maps nachsehen" className="btn btn-sm btn-link btn-line p-0 border-0 min-w-auto link-no-space text-uppercase scroll-to-id" href="#google-maps">Auf Google Maps sehen</Link>
                            </div>
                        </div>
                    </div>

                    <div className="col-sm-12 col-md-4 col-lg-4 col-xl-4">
                        <div className="information information-color-3">
                            <div className="information-icon">
                                <i className="hosco-icon-calendar"></i>
                            </div>

                            <h4 className="information-t-head">Unsere Öffnungszeiten</h4>

                            <div className="information-description">
                                <p><span className="d-flex justify-content-between flex-wrap"><span>Mo, Di, Do:</span><span className="text-right">8:00 Uhr - 12:30 Uhr <br /> 14:00 Uhr - 18:00 Uhr</span></span></p>
                                <p><span className="d-flex justify-content-between flex-wrap"><span>Mi:</span><span className="text-right">8:00 Uhr - 13:00 Uhr</span></span></p>
                                <p><span className="d-flex justify-content-between flex-wrap"><span>Fr, Sa, So:</span><span className="text-right">Geschlossen</span></span></p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default HomeInfo;
