import React from 'react';

/* import HomeNewsItems from '../../data/news/newsHomeItems.json'; */

const HomeNews = () => {
    return (
        <section id="news" /* className="block spacer p-top-xl" */>
            <div className="wrapper">
                {/* <div className="title">
                    <h2>Check Our Latest News Posts</h2>
                </div> */}

                {/* <div className="blog-shortcode">
                    <div className="row gutter-width-md with-pb-md">
                        { HomeNewsItems && HomeNewsItems.map( ( item, key ) => {
                            return(
                                <div key={ key } className="col-xl-4 col-lg-4 col-md-4 col-sm-12">
                                    <div className="card card-post">
                                        <div className="card-top position-relative">
                                            <a title={ item.title } href={ process.env.PUBLIC_URL + item.link }>
                                                <div className="img object-fit overflow-hidden">
                                                    <div className="object-fit-cover transform-scale-h">
                                                        <img className="card-top-img" src={ item.imgSrc } alt={ item.title } />
                                                    </div>
                                                </div>
                                            </a>
        
                                            <div className="post-date">
                                                <div className="post-date-0">
                                                    <p><span className="post-date-number">{ item.day }</span>{ item.month }</p>
                                                </div>
                                            </div>
                                        </div>
        
                                        <div className="card-body">
                                            <h5 className="card-title">
                                                <a title={ item.title } href={ process.env.PUBLIC_URL + item.link }>{ item.title }</a>
                                            </h5>
        
                                            <p className="card-text">{ item.description }</p>
                                        </div>
                                    </div>
                                </div>
                            );
                        })}
                    </div>
                </div> */}

             {/*    <div className="spacer m-top-lg text-right">
                    <a title="View all news" className="btn btn-primary" href={ process.env.PUBLIC_URL + "/news" }>View All Posts</a>
                </div> */}
            </div>
        </section>
    );
};

export default HomeNews;
