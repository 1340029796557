import React from 'react';

const ModalMenuPrimary = () => {
    return (
        <nav className="menu-primary">
            <ul className="nav flex-column">
                <li className="nav-item">
                    <a title="About Us" href={ process.env.PUBLIC_URL + "/#about-us" }>Über Uns</a>
                </li>

                <li className="nav-item">
                    <a title="Services" href={ process.env.PUBLIC_URL + "/#services" }>Leistungen</a>
                </li>


                <li className="nav-item">
                    <a title="Our Teams" href={ process.env.PUBLIC_URL + "/#our-team" }>Dr. Rahimi</a>
                </li>

                <li className="nav-item">
                    <a title="Testimonials" href={ process.env.PUBLIC_URL + "/#testimonials" }>Erfahrungen</a>
                </li>

                {/* <li className={ "nav-item " + ( window.location.pathname.includes( "/news" ) || window.location.pathname.includes( "/news-single-page" )? "current-nav-item" : "" ) }>
                    <a title="News" href={ process.env.PUBLIC_URL + "/news" }>News</a>
                </li> */}

                <li className="nav-item">
                    <a title="Contacts" href={ process.env.PUBLIC_URL + "/#contacts" }>Kontakt</a>
                </li>
            </ul>
        </nav>
    );
};

export default ModalMenuPrimary;
