import React, { Component } from 'react';

class ContactForm extends Component {
    constructor(props) {
        super(props);

        this.state = {
            values: {
                author: '',
                phone: '',
                email: '',
                question: ''
            },
            successMessage: "Sender's message was sent successfully",
            warningMessage: 'Fill up the form, please!',
            errorMessage: 'Something went wrong. Try again later!',
            alertClass: '',
            responseMessage: '',
            alertTimeout: '',
            delay: 5000,
            isSubmitting: false
        };
    }

    componentDidMount() {
        window.emailjs.init("4WNP4CbRXHE1yZQQf"); // Ersetzen Sie dies mit Ihrer EmailJS-Benutzer-ID
    }

    submitForm = async e => {
        e.preventDefault();

        if (document.querySelector('#alert')) {
            document.querySelector('#alert').remove();
        }

        this.setState({ isSubmitting: true });

        try {
            const result = await window.emailjs.send(
                "service_j2dk8s8", // Ersetzen Sie dies mit Ihrer Service-ID
                "template_uzoikrb", // Ersetzen Sie dies mit Ihrer Vorlagen-ID
                {
                    from_name: this.state.values.author,
                    from_email: this.state.values.email,
                    phone: this.state.values.phone,
                    message: this.state.values.question
                }
            );

            if (result.text === 'OK') {
                this.setState({ responseMessage: this.state.successMessage });
                this.callAlert(this.state.responseMessage, 'success');
            } else {
                throw new Error('Failed to send email');
            }
        } catch (error) {
            console.error(error);
            this.setState({ responseMessage: this.state.errorMessage });
            this.callAlert(this.state.responseMessage, 'error');
        }

        this.setState({ isSubmitting: false });
    };

    removeAlert = () => {
        clearTimeout(this.state.alertTimeout);
        this.setState({
            alertTimeout: setTimeout(function() {
                var element = document.querySelector('#alert');
                element.classList.remove('fadeIn');
                element.classList.add('fadeOut');
                setTimeout(function() {
                    element.remove()
                }, 900)
            }, this.state.delay)
        });
    };

    callAlert = (message, type) => {
        if (!document.querySelector('#alert')) {
            if (type === 'success') {
                this.setState({ alertClass: 'success' })
            }

            if (type === 'error') {
                this.setState({ alertClass: 'danger' })
            }

            if (type === 'warning') {
                this.setState({ alertClass: 'warning' })
            }

            var alert = '<div id="alert" class="animated fadeIn alert alert--shadow alert-' + this.state.alertClass + '">' + message + '</div>';

            var element = document.querySelector('#cf-1');

            element.insertAdjacentHTML('beforeend', alert);

            this.removeAlert();
        }
    };

    handleInputChange = e =>
        this.setState({
            values: {
                ...this.state.values,
                [e.target.name]: e.target.value
            }
        });

    render() {
        return (
            <form onSubmit={this.submitForm} id="cf-1" className="contact-form">
                <div className="form-group form-group-xs">
                    <p className="input-group gutter-width-md no-space">
                        <span className="gutter-width">
                            <input name="author" 
                                type="text" 
                                className="form-control form-lg bg-white" 
                                id="cf-1-name" 
                                placeholder="Name *"
                                value={this.state.values.author}
                                onChange={this.handleInputChange} 
                                required="required" />
                        </span>

                        <span className="gutter-width">
                            <input name="phone" 
                                type="text" 
                                className="form-control form-lg bg-white" 
                                id="cf-1-phone" 
                                placeholder="Telefon Nummer *"
                                value={this.state.values.phone}
                                onChange={this.handleInputChange} 
                                required="required" />
                        </span>
                    </p>
                </div>

                <div className="form-group form-group-xs">
                    <p className="input-group gutter-width-md no-space">
                        <span className="gutter-width">
                            <input name="email" 
                                type="email" 
                                className="form-control form-lg bg-white" 
                                id="cf-1-email" 
                                placeholder="Email" 
                                value={this.state.values.email}
                                onChange={this.handleInputChange}
                                required="required" />
                        </span>

                        <span className="gutter-width">
                            <input name="question" 
                                type="text" 
                                className="form-control form-lg bg-white" 
                                id="cf-1-question" 
                                placeholder="Frage *"
                                value={this.state.values.question}
                                onChange={this.handleInputChange} 
                                required="required" />
                        </span>
                    </p>
                </div>

                <div className="form-group form-group-xs mb-0">
                    <button type="submit" className="btn btn-primary" disabled={this.state.isSubmitting}>
                        {this.state.isSubmitting ? 'Sending...' : 'Senden'}
                    </button>
                </div>
            </form>
        );
    }
}

export default ContactForm;